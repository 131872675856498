import React, { useState } from 'react'
import { ProjectQuery } from '../../../graphql-types'
import styles from '../../styles/project/ProjectCarousel.module.css'
const Carousel = require('react-responsive-carousel').Carousel
import 'react-responsive-carousel/lib/styles/carousel.css'
import ForwardIcon from '../svg/ForwardIcon'
import BackIcon from '../svg/BackIcon'
import classNames from 'classnames'

export interface Props {
  data: ProjectQuery;
}

const ProjectCarousel = ({data: {sanityFeaturedProjects: {images}}}: Props): JSX.Element => {
  const [ currentImage, setCurrentImage ] = useState(0)

  const handleNextImage = () => {
    setCurrentImage(currentImage === images.length - 1 ? 0 : currentImage + 1)
  }

  const handleBackImage = () => {
    setCurrentImage(currentImage === 0 ? images.length - 1 : currentImage - 1)
  }

  return (
    <div className={ styles.container }>
      <Carousel
        showArrows={ false }
        showStatus={ false }
        showThumbs={ false }
        showIndicators={ false }
        selectedItem={ currentImage }
      >
        {
          images.map((image) => {
            return (
              <div key={ image.asset?.id } className={ styles.carouselImage }
                   style={ { backgroundImage: `url(${ image.asset.url })` } }>
                <div
                  className={ classNames(styles.carouselControl, styles.leftControl) }
                  onClick={ handleBackImage }/>
                <div
                  className={ classNames(styles.carouselControl, styles.rightControl) }
                  onClick={ handleNextImage }/>
              </div>
            )
          })
        }
      </Carousel>
      <div className={ styles.control }>
        <div onClick={ handleBackImage } className={ styles.nextImage }>
          <BackIcon className={ styles.nextImage }/>
        </div>
        <div onClick={ handleNextImage } className={ styles.nextImage }>
          <ForwardIcon className={ styles.nextImage }/>
        </div>
        <p
          className={ styles.imageCount }>{ currentImage + 1 } of { images.length }</p>
      </div>
    </div>
  )
}

export default ProjectCarousel
