import React from 'react'
import ForwardIcon from './ForwardIcon'
import classNames from 'classnames'
import styles from '../../styles/svg.module.css'

export interface Props {
  circleColor?: string;
  arrowColor?: string;
  className?: string;
}

const BackIcon = ({ className, ...props }: Props): JSX.Element => {
  return (
    <ForwardIcon
      className={ classNames(styles.backIcon, className) } { ...props } />
  )
}

export default BackIcon
