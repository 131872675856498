import React from 'react';
import '../styles/index.css';
import PageLayout from "../components/PageLayout";
import { graphql } from "gatsby";
import { ProjectQuery } from '../../graphql-types'
import OtherFeatured from '../components/project/OtherFeatured'
import FeaturedProject from '../components/project/FeaturedProject'
import ProjectCarousel from '../components/project/ProjectCarousel'
import ProjectInfo from '../components/project/ProjectInfo'
import { Hidden } from '@material-ui/core'

interface Props {
  data: ProjectQuery;
}

const Project = ({data}: Props) => {
  return (
    <PageLayout
      title={ `${ data.sanityFeaturedProjects.name } | FS Woodworks` }>
      <FeaturedProject data={ data }/>
      <ProjectCarousel data={ data }/>
      <ProjectInfo data={ data }/>
      <Hidden xsDown implementation='css'>
        <OtherFeatured data={ data }/>
      </Hidden>
    </PageLayout>
  );
}

export const query = graphql`
  query project($name: String) {
    sanityFeaturedProjects(name: {eq: $name}) {
      name
      header
      workDone
      completedAt(formatString: "MMM YYYY")
      _rawBodyText
      team
      images {
        asset {
          id
          url
        }
      }
    }
    otherFeatured: allSanityFeaturedProjects(filter: {name: {ne: $name}}, limit: 3) {
      nodes {
        id
        name
        isTextDark
        images {
          asset {
            url
          }
        }
      }
    }
    allSanityFeaturedProjects {
      nodes {
        id
        name
        isTextDark
        images {
          asset {
            url
          }
        }
      }
    }
  }
`

export default Project;
