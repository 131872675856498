import React from 'react'
import { ProjectQuery } from '../../../graphql-types'
import styles from '../../styles/project/FeaturedProject.module.css'
import 'react-responsive-carousel/lib/styles/carousel.css'

export interface Props {
  data: ProjectQuery
}

const FeaturedProject = ({ data: { sanityFeaturedProjects: { name, workDone } } }: Props): JSX.Element => {
  return (
    <section className={ styles.container }>
      <h1 className={ styles.header }>{ name }</h1>
      <p className={ styles.generalInfo }>{ workDone.join(' // ') }</p>
    </section>
  )
}

export default FeaturedProject
