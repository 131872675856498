import React from 'react'
import { ProjectQuery } from '../../../graphql-types'
import styles from '../../styles/project/ProjectInfo.module.css'
import 'react-responsive-carousel/lib/styles/carousel.css'
import SectionHeader from '../SectionHeader'
import { Hidden } from '@material-ui/core'
import BackIcon from '../svg/BackIcon'
import ForwardIcon from '../svg/ForwardIcon'
import { Link } from 'gatsby'

const BlockContent = require('@sanity/block-content-to-react')

export interface Props {
  data: ProjectQuery
}

const ProjectInfo = ({ data: { sanityFeaturedProjects: { header, images, _rawBodyText, team, name }, allSanityFeaturedProjects } }: Props): JSX.Element => {
  const projIndex = allSanityFeaturedProjects.nodes.findIndex((proj) => proj.name === name)
  const prevIndex = !projIndex ? allSanityFeaturedProjects.nodes.length - 1 : projIndex - 1
  const nextIndex = projIndex === allSanityFeaturedProjects.nodes.length - 1 ? 0 : projIndex + 1
  return (
    <section className={ styles.container }>
      <SectionHeader dark className={ styles.title }>{ header }</SectionHeader>
      <div className={ styles.projectInfo }>
        <div className={ styles.bodyText }>
          <p className={ styles.header }>Our Role</p>
          <BlockContent blocks={ _rawBodyText }/>
        </div>
        <div className={ styles.team }>
          <p className={ styles.header }>Contributing Team</p>
          <hr className={ styles.teamRule }/>
          {
            team.map((teamMember) => (
              <p className={ styles.teamMember }
                 key={ teamMember }>{ teamMember }</p>
            ))
          }
        </div>
      </div>
      <Hidden smUp implementation='css'>
        <div className={ styles.thumbs }>
          { images.map((image, i) => {
            if (!i) return null
            return (
              <div className={ styles.thumb } key={ i }
                   style={ { backgroundImage: `url(${ image?.asset?.url })` } }/>
            )
          }) }
        </div>
        <div className={ styles.links }>
          <Link className={ styles.link }
                to={ `/project/${ allSanityFeaturedProjects.nodes[prevIndex].id }` }>
            <BackIcon className={ styles.back }/>
          </Link>
          <h3 className={ styles.next }>Next Project</h3>
          <Link className={ styles.link }
                to={ `/project/${ allSanityFeaturedProjects.nodes[nextIndex].id }` }>
            <ForwardIcon className={ styles.forward }/>
          </Link>
        </div>
      </Hidden>
      </section>
  )
}

export default ProjectInfo
