import React from 'react'
import styles from '../../styles/project/OtherFeatured.module.css'
import { ProjectQuery } from '../../../graphql-types'
import { Link } from 'gatsby'
import ForwardIcon from '../svg/ForwardIcon'

export interface Props {
  data: ProjectQuery;
}

const OtherFeatured = ({ data: { otherFeatured: { nodes: projects } } }: Props): JSX.Element => {

  return (
    <section className={ styles.container }>
      <h3 className={ styles.header }>Other Featured Work</h3>
      <div className={ styles.images }>
        {
          projects.map(({ id, name, images, isTextDark }) => {
            const color = isTextDark ? 'black' : 'white'
            return (
              <Link to={ `/project/${ id }` } className={ styles.link }
                    style={ { backgroundImage: `url(${ images[0].asset?.url })` } }
                    key={ id }>
                <ForwardIcon arrowColor={ color } circleColor={ color }
                             className={ styles.icon }/>
                <p className={ styles.projectName }
                   style={ { color } }>{ name }</p>
              </Link>
            )
          })
        }
      </div>
      <div className={styles.bottomSpace} />
    </section>
  )
}

export default OtherFeatured
